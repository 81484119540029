import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Layout, SEO, Hero, Contact, Map } from '../components';

function NotFoundPage() {
  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Hero
        image={file.childImageSharp.fluid}
        heading="404 Page Not Found"
        copy="Sorry, the page you were looking for could not be found."
        hasCTA
        hasGradient={false}
      />
      <Contact />
      <Map />
    </Layout>
  );
}

export default NotFoundPage;
